import axios from './axiosInstance';

export function getComponentsByCategoryIdApi(categoryId) {
    let url = '/components';
    if (categoryId) {
        url += `?categoryId=${categoryId}`;
    }
    return axios.get(url).then(res => res.data);
}

export function postComponentsApi(components) {
    return axios.post('/components', components).then(res => res.data);
}
export function deleteComponentApi(id) {
    return axios.delete(`/components/${id}`).then(res => res.data);
}
export function deleteAllComponentsApi() {
    return axios.delete('/components').then(res => res.data);
}
export function updateComponentApi(component) {
    return axios
        .put(`/components/${component.id}`, component)
        .then(res => res.data);
}
