import Vue from 'vue';
import Vuex from 'vuex';
import * as userMessages from './usermessages';
import * as users from './users';
import * as companies from './companies';
import * as products from './products';
import * as navigation from './navigation';
import * as countries from './countries';
import * as protocols from './protocols';
import * as substances from './substances';
import * as texts from './texts';
import * as components from './components';
import * as productCompliances from './productCompliances';
import * as hsCodeProducts from './hsCodeProducts';
import * as regulationScopes from './regulationScopes';
import * as requiredItems from './requiredItems';
import * as conformityMarkings from './conformityMarkings';
import * as search from './search';
import * as tests from './tests';
import * as adeo from './adeo';
import * as languages from './languages';
import * as labRequirements from './labRequirements';
import * as documents from './documents';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [createPersistedState({ key: `patool-${process.env.NODE_ENV}` })],
    modules: {
        userMessages,
        users,
        companies,
        products,
        navigation,
        countries,
        protocols,
        substances,
        texts,
        components,
        productCompliances,
        hsCodeProducts,
        regulationScopes,
        requiredItems,
        conformityMarkings,
        search,
        tests,
        adeo,
        languages,
        labRequirements,
        documents,
    },
    actions: {
        refresh({ commit }) {
            return new Promise(resolve => {
                commit('userMessages/clearState');
                commit('companies/clearState');
                commit('products/clearState');
                commit('navigation/clearState');
                commit('countries/clearState');
                commit('protocols/clearState');
                commit('substances/clearState');
                commit('texts/clearState');
                commit('tests/clearState');
                commit('components/clearState');
                commit('regulationScopes/clearState');
                commit('requiredItems/clearState');
                commit('conformityMarkings/clearState');
                commit('search/clearState');
                commit('languages/clearState');
                commit('documents/clearState');
                return resolve();
            });
        },
        async clearState(context) {
            await context.dispatch('refresh');
            return new Promise(resolve => {
                context.commit('users/clearState');
                return resolve();
            });
        },
    },
});

export default store;
