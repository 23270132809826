import axios from './axiosInstance';
import { addUrlParameter } from '../utils';

export function getTextsByCountryIdApi(
    countryId,
    textType,
    page,
    itemsPerPage,
    search,
    outOfSync
) {
    let url = '/texts';
    if (countryId) url += `?countryId=${countryId}`;
    if (textType) {
        url = addUrlParameter(url, 'textType', textType);
    }
    if (page >= 0) {
        url = addUrlParameter(url, 'page', page);
    }
    if (itemsPerPage) {
        url = addUrlParameter(url, 'itemsPerPage', itemsPerPage);
    }
    if (search) {
        url = addUrlParameter(url, 'search', search);
    }
    if (outOfSync) {
        url = addUrlParameter(url, 'outOfSync', outOfSync);
    }

    return axios.get(url).then(res => res.data);
}

export function postTextApi(text, replace) {
    let url = `/text`;
    if (replace) {
        url += '?replace=true';
    }
    return axios.post(url, text).then(res => res.data);
}
export function postTextsApi(texts) {
    return axios.post(`/texts`, texts).then(res => res.data);
}
export function deleteAllTextsApi(countryId) {
    if (countryId)
        return axios
            .delete(`texts?countryId=${countryId}`)
            .then(res => res.data);
    else return axios.delete(`texts`).then(res => res.data);
}
export function deleteTextApi(id) {
    return axios.delete(`/texts/${id}`).then(res => res.data);
}
export function updateTextApi(text) {
    return axios.put(`/texts/${text.id}`, text).then(res => res.data);
}
export function getLegalClassificationsApi() {
    return axios.get('/legalClassifications').then(res => res.data);
}
export function getRequirementTypesApi() {
    return axios.get('/requirementTypes').then(res => res.data);
}
export function getMandatoryTypesApi() {
    return axios.get('/mandatoryTypes').then(res => res.data);
}
export function getComplianceTypesApi() {
    return axios.get('/complianceTypes').then(res => res.data);
}

export function getTextsByProtocolApi(protocolId) {
    return axios.get(`/protocols/${protocolId}/texts`).then(res => res.data);
}

export function getStandardsByProtocolApi(protocolId) {
    return axios
        .get(`/protocols/${protocolId}/standards`)
        .then(res => res.data);
}

export function getAllStandardsApi() {
    return axios.get(`/standards`).then(res => res.data);
}
export function uploadTextApi(textId) {
    return axios.put(`/texts/${textId}/upload`).then(res => res.data);
}

export function getTextApi(id) {
    return axios.get(`/texts/${id}`).then(res => res.data);
}

export function markAsDuplicateApi(sourceId, replacementId) {
    return axios
        .put(`/texts/${sourceId}/duplicate/${replacementId}`)
        .then(res => res.data);
}
