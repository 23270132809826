import {
    getAllUsersApi,
    getRolesApi,
    postUserApi,
    updateUserApi,
    deleteUserApi,
    getUserByNameApi,
    getUserByIdAndTokenApi,
    login,
    setPasswordApi,
} from '../api/users';
import { setupHeaders, clearAuthorization } from '../api/axiosInstance';
import decode from 'jwt-decode';

function getTokenExpirationDate(encodedToken) {
    const token = decode(encodedToken);
    if (!token.exp) {
        return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
}

function getUsername(token) {
    return decode(token).sub;
}

function getHeaders(token) {
    return {
        token,
        username: getUsername(token),
        expirationDate: getTokenExpirationDate(token).toISOString(),
    };
}

function initialState() {
    return {
        currentUser: null,
        token: null,
        roles: null,
        users: [],
    };
}

export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setUser(state, user) {
        state.currentUser = user;
    },
    setUsers(state, users) {
        state.users = users;
    },
    postUser(state, user) {
        const existingUser = state.users.find(u => u.id === user.id);
        if (!existingUser) {
            state.users.push(user);
        }
    },
    updateUser(state, user) {
        const index = state.users.findIndex(u => u.id === user.id);
        if (index !== -1) {
            state.users.splice(index, 1, user);
        }
    },
    deleteUser(state, id) {
        const index = state.users.findIndex(u => u.id === id);
        if (index !== -1) {
            state.users.splice(index, 1);
        }
    },
    clearUser(state) {
        state.currentUser = null;
    },
    setToken(state, token) {
        state.token = token;
    },
    setHeaders(state, token) {
        state.headers = getHeaders(token);
        setupHeaders(state.headers);
    },
    deleteToken() {
        state.token = null;
    },
    setRoles(state, roles) {
        state.roles = roles;
    },
};

export const actions = {
    async fetchUsers({ commit }) {
        const users = await getAllUsersApi();
        commit('setUsers', users);
    },
    async getRoles({ state, commit }) {
        if (!state.roles) {
            const roles = await getRolesApi();
            commit('setRoles', roles);
        }
        return state.roles;
    },
    async postUser({ commit }, user) {
        const newUser = await postUserApi(user);
        commit('postUser', newUser);
    },
    async updateUser({ commit }, user) {
        const updatedUser = await updateUserApi(user);
        commit('updateUser', updatedUser);
    },
    async deleteUser({ commit }, id) {
        await deleteUserApi(id);
        commit('deleteUser', id);
    },
    setPassword(context, { id, password }) {
        return setPasswordApi(id, password);
    },
    async getUserByNameAndStore({ commit, state }, name) {
        if (state.currentUser) {
            return state.currentUser;
        } else {
            const user = await getUserByNameApi(name);
            commit('setUser', user);
            return user;
        }
    },
    async getUserByIdAndToken(context, { id, token }) {
        return getUserByIdAndTokenApi(id, token);
    },
    async getEncodedTokenAndStore({ dispatch, commit, state }) {
        if (!state.token || state.token.length === 0) {
            return null;
        }
        const token = state.token;
        commit('setToken', token);
        commit('setHeaders', token);

        const username = getUsername(token);
        if (!state.currentUser || state.currentUser.name != username) {
            dispatch('getUserByNameAndStore', username).then(() => token);
        }
        return token;
    },
    async isLoggedIn({ dispatch }) {
        const token = await dispatch('getEncodedTokenAndStore');
        if (!token) {
            return false;
        } else {
            const exp = getTokenExpirationDate(token);
            if (exp < new Date()) {
                return false;
            }
            return decode(token).sub;
        }
    },
    async logout({ commit, state }, redirect) {
        commit('deleteToken');
        commit('clearUser');
        state.token = null;
        clearAuthorization();
        commit('clearState');
        window.location.href = redirect;
    },
    async authenticate(
        { dispatch, commit },
        { username, password, router, redirect }
    ) {
        const token = await login(username, password);
        if (!token) {
            console.log(`token=${token}`);
            alert("Problème lors de l'authentification");
            return;
        }
        commit('setToken', token);
        commit('setHeaders', token);
        const res = await dispatch('getUserByNameAndStore', username);
        console.log(res);
        if (redirect.indexOf('http') === 0) {
            window.location.href = redirect;
        } else {
            router.replace(redirect);
        }
    },
};

export const namespaced = true;
