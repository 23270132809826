import axios from './axiosInstance';

export function getAllSubstancesApi(categoryId) {
    let url = '/substances';
    if (categoryId) {
        url += `?categoryId=${categoryId}`;
    }
    return axios.get(url).then(res => res.data);
}
export function postSubstancesApi(substances) {
    return axios.post('/substances', substances).then(res => res.data);
}
export function deleteAllSubstancesApi() {
    return axios.delete('/substances').then(res => res.data);
}
export function deleteSubstanceApi(id) {
    return axios.delete(`/substances/${id}`).then(res => res.data);
}
export function updateSubstanceApi(substance) {
    return axios
        .put(`/substances/${substance.id}`, substance)
        .then(res => res.data);
}
export function getAllSubstanceCategoriesApi() {
    return axios.get('substances/categories').then(res => res.data);
}
export function getPrioritarySubstancesApi(countryId) {
    return axios
        .get(`/substances?countryId=${countryId}`)
        .then(res => res.data);
}
