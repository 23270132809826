import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

const options = {
    theme: {
        themes: {
            light: {
                primary: '#064f5d',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
    options: {
        customProperties: true,
    },
    icons: {
        iconfont: 'md',
    },
};

Vue.use(Vuetify);

export default new Vuetify(options);
