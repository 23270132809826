import axios from './../axiosInstance';

export function getCommentsByRequestIdApi(requestId) {
    return axios.get(`/comments/${requestId}`).then(res => res.data);
}

export function postComment(actionId, type, comment) {
    return axios
        .post(`/comments/${actionId}?type=${type}`, comment)
        .then(res => res.data);
}
