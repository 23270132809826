import {
    getTextsByCountryIdApi,
    deleteTextApi,
    updateTextApi,
    deleteAllTextsApi,
    postTextApi,
    postTextsApi,
    getLegalClassificationsApi,
    getRequirementTypesApi,
    getMandatoryTypesApi,
    getComplianceTypesApi,
    getTextsByProtocolApi,
    getStandardsByProtocolApi,
    getAllStandardsApi,
    uploadTextApi,
    getTextApi,
    markAsDuplicateApi,
} from '@/api/texts';

function initialState() {
    return {
        texts: [],
        selectedText: null,
        legalClassifications: [],
        requirementTypes: [],
        mandatoryTypes: [],
        complianceTypes: [],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setTexts(state, texts) {
        state.texts = texts;
    },
    setSelectedText(state, text) {
        state.selectedText = text;
    },
    postText(state, text) {
        state.texts.push(text);
    },
    postTexts(state, texts) {
        state.texts.push(...texts);
    },
    updateText(state, text) {
        let index = state.texts.findIndex(p => p.id === text.id);
        if (index !== -1) {
            state.texts.splice(index, 1, text);
        }
    },
    deleteText(state, id) {
        const index = state.texts.findIndex(p => p.id === id);
        if (index !== -1) {
            state.texts.splice(index, 1);
        }
    },
    setLegalClassifications(state, legalClassifications) {
        state.legalClassifications = legalClassifications;
    },
    setRequirementTypes(state, types) {
        state.requirementTypes = types;
    },
    setMandatoryTypes(state, types) {
        state.mandatoryTypes = types;
    },
    setComplianceTypes(state, types) {
        state.complianceTypes = types;
    },
};

export const actions = {
    async fetchTextsByCountryId({ commit }, id) {
        const texts = await getTextsByCountryIdApi(id);
        commit('setTexts', texts);
    },
    async getTextsByCountryId(
        context,
        { countryId, textType, page, itemsPerPage, search, outOfSync }
    ) {
        return getTextsByCountryIdApi(
            countryId,
            textType,
            page,
            itemsPerPage,
            search,
            outOfSync
        );
    },
    async postText({ commit }, { text, replace }) {
        const newText = await postTextApi(text, replace);
        commit('postText', newText);
        return newText;
    },
    async postTexts({ commit }, texts) {
        const newTexts = await postTextsApi(texts);
        commit('postTexts', newTexts);
        return newTexts;
    },
    async updateText({ commit }, text) {
        const updatedText = await updateTextApi(text);
        commit('updateText', updatedText);
        return updatedText;
    },
    async deleteText({ commit }, id) {
        await deleteTextApi(id);
        commit('deleteText', id);
    },
    async deleteAllTexts({ commit }, countryId) {
        await deleteAllTextsApi(countryId);
        commit('setTexts', []);
    },
    async fetchLegalClassifications({ state, commit }) {
        if (state.legalClassifications.length) {
            return state.legalClassifications;
        }
        const res = await getLegalClassificationsApi();
        commit('setLegalClassifications', res);
    },
    async fetchRequirementTypes({ state, commit }) {
        if (state.requirementTypes.length) {
            return state.requirementTypes;
        }
        const res = await getRequirementTypesApi();
        commit('setRequirementTypes', res);
    },
    async fetchMandatoryTypes({ state, commit }) {
        if (state.mandatoryTypes.length) {
            return state.mandatoryTypes;
        }
        const res = await getMandatoryTypesApi();
        commit('setMandatoryTypes', res);
    },
    async fetchComplianceTypes({ state, commit }) {
        if (state.complianceTypes.length) {
            return state.complianceTypes;
        }
        const res = await getComplianceTypesApi();
        commit('setComplianceTypes', res);
    },
    getTextsByProtocol(context, protocolId) {
        return getTextsByProtocolApi(protocolId);
    },
    getStandardsByProtocol(context, protocolId) {
        return getStandardsByProtocolApi(protocolId);
    },
    getAllStandards() {
        return getAllStandardsApi();
    },
    uploadText(context, textId) {
        return uploadTextApi(textId);
    },
    getText(context, id) {
        return getTextApi(id);
    },
    markAsDuplicate(context, { sourceId, replacementId }) {
        return markAsDuplicateApi(sourceId, replacementId);
    },
};

export const namespaced = true;
