import axios from './axiosInstance';

export function getAllHSCodeProductsApi() {
    return axios.get('/hscodeproducts').then(res => res.data);
}
export function postHSCodeProductsApi(products) {
    return axios.post('/hscodeproducts', products).then(res => res.data);
}
export function deleteAllHSCodesProductsApi() {
    return axios.delete('/hscodeproducts').then(res => res.data);
}
export function deleteHSCodeProductApi(id) {
    return axios.delete(`/hscodeproducts/${id}`).then(res => res.data);
}
export function updateHSCodeProductApi(product) {
    return axios
        .put(`/hscodeproducts/${product.id}`, product)
        .then(res => res.data);
}
