import { getDocumentTypesApi, uploadDocumentFileApi } from '@/api/documents';

function initialState() {
    return {
        documentTypes: [],
    };
}

export const state = initialState();

export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setDocumentTypes(state, types) {
        state.documentTypes = types;
    },
};

export const actions = {
    async fetchDocumentTypes({ state, commit }) {
        if (state.documentTypes.length) {
            return state.documentTypes;
        }
        const res = await getDocumentTypesApi();
        commit('setDocumentTypes', res);
    },
    async uploadDocumentFile(context, { file, onUploadProgress }) {
        return uploadDocumentFileApi(file, onUploadProgress);
    },
};

export const namespaced = true;
