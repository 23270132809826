import axios from './axiosInstance';

export function getProductCompliancesByProtocolIdApi(protocolId) {
    return axios
        .get(`/protocols/${protocolId}/productCompliances`)
        .then(res => res.data);
}
export function postProductCompliance(protocolId) {
    return axios
        .post(`/protocols/${protocolId}/productCompliances`)
        .then(res => res.data);
}
export function deleteProductComplianceApi(id) {
    return axios.delete(`/productCompliances/${id}`).then(res => res.data);
}
export function updateProductComplianceApi(compliance) {
    return axios
        .put(`/productCompliances/${compliance.id}`, compliance)
        .then(res => res.data);
}
