import axios from './axiosInstance';

export function getAllLanguagesApi() {
    return axios.get('/languages').then(res => res.data);
}

export function postLanguageApi(language) {
    return axios.post('/languages', language).then(res => res.data);
}
export function deleteLanguageApi(id) {
    return axios.delete(`/languages/${id}`).then(res => res.data);
}
export function updateLanguageApi(language) {
    return axios
        .put(`/languages/${language.id}`, language)
        .then(res => res.data);
}
