import {
    getConformityMarkingsApi,
    uploadConformityMarkingApi,
    updateConformityMarkingApi,
    addConformityMarkingApi,
} from '@/api/conformityMarkings';

function initialState() {
    return {
        conformityMarkings: [],
        conformityMarkingMandatoryTypes: [],
    };
}
export const state = initialState();

export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setConformityMarkings(state, markings) {
        state.conformityMarkings = markings;
    },
    setConformityMarkingMandatoryTypes(state, types) {
        state.conformityMarkingMandatoryTypes = types;
    },
};

export const actions = {
    async uploadConformityMarking(context, { file, onUploadProgress }) {
        return uploadConformityMarkingApi(file, onUploadProgress);
    },

    async fetchConformityMarkings({ commit }) {
        const markings = await getConformityMarkingsApi();
        commit('setConformityMarkings', markings);
    },
    async getConformityMarkings() {
        return getConformityMarkingsApi();
    },
    async updateConformityMarking(context, marking) {
        return updateConformityMarkingApi(marking);
    },
    async addConformityMarking() {
        return addConformityMarkingApi();
    },
};

export const namespaced = true;
