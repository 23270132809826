<template>
    <div>
        <v-app-bar flat app class="primary white--text">
            <v-app-bar-nav-icon
                class="white--text"
                @click="drawer = true"
                x-large
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="white--text text-h3 font-weight-bold">
                {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <v-chip
                label
                v-if="userName"
                class="white--text user-details"
                color="info"
            >
                {{ userName }} ({{ company }})
            </v-chip>

            <v-btn icon large @click="onLogOut" v-if="currentUser">
                <v-icon x-large class="white--text">
                    power_settings_new
                </v-icon>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            v-if="currentUser"
        >
            <v-list nav dense>
                <template v-for="menuItem in navigation">
                    <v-list-group
                        :key="menuItem.name"
                        v-if="
                            menuItem.children &&
                                (!menuItem.roles ||
                                    menuItem.roles.includes(currentUser.role))
                        "
                        :prepend-icon="menuItem.icon"
                        ><template v-slot:activator>
                            <v-list-item>
                                {{ menuItem.name }}
                            </v-list-item>
                        </template>
                        <v-list-item
                            v-for="child in menuItem.children"
                            :key="child.name"
                            :to="{ name: child.to }"
                            class="pl-10"
                        >
                            <v-list-item-icon
                                ><v-icon>{{
                                    child.icon
                                }}</v-icon></v-list-item-icon
                            >
                            {{ child.name }}</v-list-item
                        >
                    </v-list-group>
                    <v-list-item
                        v-else-if="
                            !menuItem.roles ||
                                menuItem.roles.includes(currentUser.role)
                        "
                        :to="{ name: menuItem.to }"
                        :key="menuItem.name"
                    >
                        <v-list-item-icon
                            ><v-icon>{{
                                menuItem.icon
                            }}</v-icon></v-list-item-icon
                        >
                        {{ menuItem.name }}</v-list-item
                    >
                </template>
                <v-list-item
                    v-if="currentUser.role === 'superadmin'"
                    @click="clearStore"
                >
                    <v-list-item-icon><v-icon>fa-sync</v-icon></v-list-item-icon
                    >Clear Store
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
export default {
    name: 'Navigation',
    props: {
        title: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            drawer: false,
        };
    },
    computed: {
        ...mapState('users', ['currentUser']),
        ...mapState('navigation', ['navigation']),
        userName() {
            return this.currentUser
                ? `${this.currentUser.firstName} ${this.currentUser.lastName}`
                : undefined;
        },
        company() {
            return this.currentUser ? this.currentUser.company.name : undefined;
        },
    },
    methods: {
        ...mapMutations('users', ['clearUser', 'logout']),
        ...mapActions('users', ['logout']),
        clearStore() {
            this.$store.dispatch('refresh');
        },
        onLogOut() {
            this.clearUser();
            this.$store.dispatch('clearState');
            this.logout(process.env.VUE_APP_LOGIN);
        },
    },
};
</script>

<style scoped></style>
