import { getAllRegulationScopesApi } from '@/api/regulationScopes';

function initialState() {
    return {
        regulationScopes: [],
    };
}
export const state = initialState();

export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setRegulationScopes(state, scopes) {
        state.regulationScopes = scopes;
    },
};

export const actions = {
    async fetchRegulationScopes({ commit, state }) {
        if (state.regulationScopes.length) {
            return state.regulationScopes;
        }
        const scopes = await getAllRegulationScopesApi();
        commit('setRegulationScopes', scopes);
    },
};

export const namespaced = true;
