import {
    synchronizeQualityModelsApi,
    synchronizeProtocolApi,
    previewProtocolSynchronisationApi,
    getQMSRegulationNotInCWatchAPI,
} from '@/api/adeo/qualityModels';
import { synchronizeRegionsApi } from '@/api/adeo/regions';
import {
    synchronizeRegulationsApi,
    createOrUpdateRegulationApi,
    getRegulationApi,
} from '@/api/adeo/regulations';
import {
    getAllProtocolRequestsApi,
    getRequestProtocolsApi,
    deleteProtocolRequestApi,
    completeProtocolRequestApi,
} from '@/api/adeo/protocolRequests';
import { synchronizeLogosApi } from '@/api/adeo/logos';
import { getCommentsByRequestIdApi, postComment } from '@/api/adeo/comments';

export const actions = {
    synchronizeQualityModels() {
        return synchronizeQualityModelsApi();
    },
    synchronizeRegions() {
        return synchronizeRegionsApi();
    },
    synchronizeRegulations() {
        return synchronizeRegulationsApi();
    },
    synchronizeProtocol(context, id) {
        return synchronizeProtocolApi(id);
    },
    previewProtocolSynchronisation(context, id) {
        return previewProtocolSynchronisationApi(id);
    },
    getQMSRegulationNotInCWatch(context, id) {
        return getQMSRegulationNotInCWatchAPI(id);
    },
    createOrUpdateRegulation(context, texts) {
        return createOrUpdateRegulationApi(texts);
    },
    getAllProtocolRequests(context, completed) {
        return getAllProtocolRequestsApi(completed);
    },
    synchronizeLogos() {
        return synchronizeLogosApi();
    },
    getRequestProtocols(context, requestId) {
        return getRequestProtocolsApi(requestId);
    },
    deleteProtocolRequest(context, requestId) {
        return deleteProtocolRequestApi(requestId);
    },
    completeProtocolRequest(context, { id, answer }) {
        return completeProtocolRequestApi(id, answer);
    },
    getCommentsByRequestId(context, requestId) {
        return getCommentsByRequestIdApi(requestId);
    },
    postComment(context, { actionId, type, comment }) {
        return postComment(actionId, type, comment);
    },
    getRegulation(context, id) {
        return getRegulationApi(id);
    },
};

export const namespaced = true;
