import {
    getAllCompaniesApi,
    deleteCompanyApi,
    postCompanyApi,
    updateCompanyApi,
    getTypesApi,
    getClientsApi,
} from '@/api/companies';

function initialState() {
    return {
        companies: [],
        selectedCompany: null,
        companyTypes: null,
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setCompanies(state, companies) {
        state.companies = companies;
    },
    setSelectedCompany(state, company) {
        state.selectedCompany = company;
    },
    postCompany(state, company) {
        const existingCompany = state.companies.find(c => c.id === company.id);
        if (!existingCompany) {
            state.companies.push(company);
        }
    },
    updateCompany(state, company) {
        const index = state.companies.findIndex(c => c.id === company.id);
        if (index !== -1) {
            state.companies.splice(index, 1, company);
        }
    },
    deleteCompany(state, id) {
        const index = state.companies.findIndex(c => c.id === id);
        if (index !== -1) {
            state.companies.splice(index, 1);
        }
    },
    setTypes(state, types) {
        state.companyTypes = types;
    },
};

export const actions = {
    async fetchCompanies({ commit }) {
        const companies = await getAllCompaniesApi();
        commit('setCompanies', companies);
    },
    getCompaniesByType(context, type) {
        return getAllCompaniesApi(type);
    },
    async postCompany({ commit }, company) {
        const newCompany = await postCompanyApi(company);
        commit('postCompany', newCompany);
    },
    async updateCompany({ commit }, company) {
        const updatedCompany = await updateCompanyApi(company);
        commit('updateCompany', updatedCompany);
    },
    async deleteCompany({ commit }, id) {
        await deleteCompanyApi(id);
        commit('deleteCompany', id);
    },
    async getTypes({ state, commit }) {
        if (!state.companyTypes) {
            const types = await getTypesApi();
            commit('setTypes', types);
        }
        return state.companyTypes;
    },
    async fetchClients({ commit }, id) {
        const companies = await getClientsApi(id);
        commit('setCompanies', companies);
    },
};

export const namespaced = true;
