import {
    getComponentsByCategoryIdApi,
    deleteComponentApi,
    postComponentsApi,
    updateComponentApi,
    deleteAllComponentsApi,
} from '@/api/components';

function initialState() {
    return {
        components: [],
        selectedComponent: null,
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setComponents(state, components) {
        state.components = components;
    },
    setSelectedComponent(state, component) {
        state.selectedComponent = component;
    },
    postComponents(state, components) {
        state.components.push(...components);
    },
    updateComponent(state, component) {
        const index = state.components.findIndex(c => c.id === component.id);
        if (index !== -1) {
            state.components.splice(index, 1, component);
        }
    },
    deleteComponent(state, id) {
        const index = state.components.findIndex(c => c.id === id);
        if (index !== -1) {
            state.components.splice(index, 1);
        }
    },
};

export const actions = {
    async fetchComponents({ commit }, categoryId) {
        const components = await getComponentsByCategoryIdApi(categoryId);
        commit('setComponents', components);
    },
    async postComponents({ commit }, components) {
        const newComponents = await postComponentsApi(components);
        commit('postComponents', newComponents);
        return newComponents;
    },
    async updateComponent({ commit }, component) {
        const updatedComponent = await updateComponentApi(component);
        commit('updateComponent', updatedComponent);
    },
    async deleteComponent({ commit }, id) {
        await deleteComponentApi(id);
        commit('deleteComponent', id);
    },
    async deleteAllComponents({ commit }) {
        await deleteAllComponentsApi();
        commit('setComponents', []);
    },
};

export const namespaced = true;
