var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"primary white--text",attrs:{"flat":"","app":""}},[_c('v-app-bar-nav-icon',{staticClass:"white--text",attrs:{"x-large":""},on:{"click":function($event){_vm.drawer = true}}}),_c('v-toolbar-title',{staticClass:"white--text text-h3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),(_vm.userName)?_c('v-chip',{staticClass:"white--text user-details",attrs:{"label":"","color":"info"}},[_vm._v(" "+_vm._s(_vm.userName)+" ("+_vm._s(_vm.company)+") ")]):_vm._e(),(_vm.currentUser)?_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":_vm.onLogOut}},[_c('v-icon',{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v(" power_settings_new ")])],1):_vm._e()],1),(_vm.currentUser)?_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.navigation),function(menuItem){return [(
                        menuItem.children &&
                            (!menuItem.roles ||
                                menuItem.roles.includes(_vm.currentUser.role))
                    )?_c('v-list-group',{key:menuItem.name,attrs:{"prepend-icon":menuItem.icon},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',[_vm._v(" "+_vm._s(menuItem.name)+" ")])]},proxy:true}],null,true)},_vm._l((menuItem.children),function(child){return _c('v-list-item',{key:child.name,staticClass:"pl-10",attrs:{"to":{ name: child.to }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_vm._v(" "+_vm._s(child.name))],1)}),1):(
                        !menuItem.roles ||
                            menuItem.roles.includes(_vm.currentUser.role)
                    )?_c('v-list-item',{key:menuItem.name,attrs:{"to":{ name: menuItem.to }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(menuItem.icon))])],1),_vm._v(" "+_vm._s(menuItem.name))],1):_vm._e()]}),(_vm.currentUser.role === 'superadmin')?_c('v-list-item',{on:{"click":_vm.clearStore}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("fa-sync")])],1),_vm._v("Clear Store ")],1):_vm._e()],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }