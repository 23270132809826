import axios from './axiosInstance';
import { addUrlParameter } from '@/utils';

export function getRequiredItemsByProtocolAndRegulationScopeApi(
    protocolId,
    regulationScope
) {
    return axios
        .get(
            `/protocols/${protocolId}/regulationScopes/${regulationScope}/requiredItems`
        )
        .then(res => res.data);
}

export function postRequiredItemApi(regulationScope, textId, requiredItem) {
    let url = `/regulationScopes/${regulationScope}/requiredItems`;
    if (textId) {
        url += `?textId=${textId}`;
    }
    return axios.post(url, requiredItem).then(res => res.data);
}
export function updateRequiredItemApi(requiredItem) {
    return axios
        .put(`/requiredItems/${requiredItem.id}`, requiredItem)
        .then(res => res.data);
}
export function deleteRequiredItemApi(requiredItemId, protocolId) {
    let url = `/requiredItems/${requiredItemId}`;
    if (protocolId) {
        url += `?protocolId=${protocolId}`;
    }
    return axios.delete(url).then(res => res.data);
}

export function copyRequiredItemApi(requiredItemId) {
    return axios
        .post(`/requiredItems/${requiredItemId}/copy`)
        .then(res => res.data);
}

export function getRequiredItemsByTextIdApi(textId) {
    return axios.get(`/texts/${textId}/requiredItems`).then(res => res.data);
}

export function getDocumentsByProtocolIdApi(protocolId) {
    return axios
        .get(`/protocols/${protocolId}/documents`)
        .then(res => res.data);
}

export function getRequiredItemsWithSymbolByProtocolApi(protocolId) {
    return axios.get(`/protocols/${protocolId}/symbols`).then(res => res.data);
}

export function countRequiredItemsByProtocolApi(protocolId) {
    return axios
        .get(`/protocols/${protocolId}/countRequiredItems`)
        .then(res => res.data);
}

export function updateRequiredItemsApi(items) {
    return axios.put('/requiredItems', items).then(res => res.data);
}

export function getProtocolRequiredItemsByTextAndRegulationScopeApi(
    protocolId,
    textId,
    regulationScope
) {
    return axios
        .get(
            `/protocols/${protocolId}/texts/${textId}/regulationScopes/${regulationScope}/requiredItems`
        )
        .then(res => res.data);
}

export function addRequiredItemsToProtocolApi(
    protocolId,
    textId,
    requiredItemIds,
    regulationScope
) {
    return axios
        .post(
            `/protocols/${protocolId}/texts/${textId}/regulationScopes/${regulationScope}/requiredItems`,
            requiredItemIds
        )
        .then(res => res.data);
}

export function copyAllRequiredItemsApi(
    sourceId,
    regulationScope,
    destinationId
) {
    return axios
        .post(
            `/texts/${sourceId}/regulationScopes/${regulationScope}/requiredItems/copy/${destinationId}`
        )
        .then(res => res.data);
}

export function deleteAllRequiredItemsApi(textId, regulationScope) {
    return axios
        .delete(
            `/texts/${textId}/regulationScopes/${regulationScope}/requiredItems`
        )
        .then(res => res.data);
}

export function getRequiredItemsByTextAndRegulationScopeApi(
    textId,
    regulationScope,
    protocolId
) {
    let url = `/texts/${textId}/regulationScopes/${regulationScope}/requiredItems`;
    if (protocolId) {
        url += `?protocolId=${protocolId}`;
    }
    return axios.get(url).then(res => res.data);
}

export function getBySubstanceAndSearchApi(
    search,
    page,
    itemsPerPage,
    countryIds,
    componentIds,
    substanceIds,
    ageGrades,
    productIds,
    prioritarySubstances,
    itemVerification
) {
    let url = `/requiredItems/bySubstance`;
    if (search) {
        url += `?search=${search}`;
    }
    if (page >= 0) {
        url = addUrlParameter(url, 'page', page);
    }
    if (itemsPerPage) {
        url = addUrlParameter(url, 'itemsPerPage', itemsPerPage);
    }
    if (countryIds) {
        url = addUrlParameter(url, 'countryIds', countryIds);
    }
    if (componentIds) {
        url = addUrlParameter(url, 'componentIds', componentIds);
    }
    if (substanceIds) {
        url = addUrlParameter(url, 'substanceIds', substanceIds);
    }
    if (ageGrades) {
        url = addUrlParameter(url, 'ageGrades', ageGrades);
    }
    if (itemVerification) {
        url = addUrlParameter(url, 'itemVerification', itemVerification);
    }
    if (productIds) {
        url = addUrlParameter(url, 'productIds', productIds);
    }
    if (prioritarySubstances) {
        url = addUrlParameter(
            url,
            'prioritarySubstances',
            prioritarySubstances
        );
    }
    return axios.get(url).then(res => res.data);
}

export function getByComponentAndSearchApi(
    search,
    page,
    itemsPerPage,
    countryIds,
    componentIds,
    substanceIds,
    ageGrades,
    productIds,
    prioritarySubstances,
    itemVerification
) {
    let url = `/requiredItems/byComponent`;
    if (search) {
        url += `?search=${search}`;
    }
    if (page >= 0) {
        url = addUrlParameter(url, 'page', page);
    }
    if (itemsPerPage) {
        url = addUrlParameter(url, 'itemsPerPage', itemsPerPage);
    }
    if (countryIds) {
        url = addUrlParameter(url, 'countryIds', countryIds);
    }
    if (componentIds) {
        url = addUrlParameter(url, 'componentIds', componentIds);
    }
    if (substanceIds) {
        url = addUrlParameter(url, 'substanceIds', substanceIds);
    }
    if (ageGrades) {
        url = addUrlParameter(url, 'ageGrades', ageGrades);
    }
    if (itemVerification) {
        url = addUrlParameter(url, 'itemVerification', itemVerification);
    }
    if (productIds) {
        url = addUrlParameter(url, 'productIds', productIds);
    }
    if (prioritarySubstances) {
        url = addUrlParameter(
            url,
            'prioritarySubstances',
            prioritarySubstances
        );
    }
    return axios.get(url).then(res => res.data);
}

export async function excelExportBySubstanceAndSearchApi(
    search,
    countryIds,
    substanceIds,
    ageGrades,
    productIds,
    itemVerification
) {
    let url = `/requiredItems/bySubstance/excel`;
    if (search) {
        url += `?search=${search}`;
    }
    if (countryIds) {
        url = addUrlParameter(url, 'countryIds', countryIds);
    }
    if (substanceIds) {
        url = addUrlParameter(url, 'substanceIds', substanceIds);
    }
    if (ageGrades) {
        url = addUrlParameter(url, 'ageGrades', ageGrades);
    }
    if (itemVerification) {
        url = addUrlParameter(url, 'itemVerification', itemVerification);
    }
    if (productIds) {
        url = addUrlParameter(url, 'productIds', productIds);
    }
    const response = await axios({
        url, //your url
        method: 'GET',
        responseType: 'blob', // important
    });
    const resUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = resUrl;
    link.setAttribute('download', `substance_${search}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export async function excelExportByComponentAndSearchApi(
    search,
    countryIds,
    componentIds,
    ageGrades,
    productIds,
    itemVerification
) {
    let url = `/requiredItems/byComponent/excel`;
    if (search) {
        url += `?search=${search}`;
    }
    if (countryIds) {
        url = addUrlParameter(url, 'countryIds', countryIds);
    }
    if (componentIds) {
        url = addUrlParameter(url, 'componentIds', componentIds);
    }
    if (ageGrades) {
        url = addUrlParameter(url, 'ageGrades', ageGrades);
    }
    if (itemVerification) {
        url = addUrlParameter(url, 'itemVerification', itemVerification);
    }
    if (productIds) {
        url = addUrlParameter(url, 'productIds', productIds);
    }
    const response = await axios({
        url, //your url
        method: 'GET',
        responseType: 'blob', // important
    });
    const resUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = resUrl;
    link.setAttribute('download', `component_${search}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export function getSkinContactTypesApi() {
    return axios.get('/requiredItems/skinContactTypes').then(res => res.data);
}

export function getRequiredItemsByProtocolAndTestTypeApi(protocolId, testType) {
    let url = `/protocols/${protocolId}/requiredItems`;
    if (testType) {
        url = addUrlParameter(url, 'testType', testType);
    }
    return axios.get(url).then(res => res.data);
}

export function getAgeGradesApi() {
    return axios.get('/requiredItems/ageGrades').then(res => res.data);
}

export function addRequiredItemToSummaryApi(protocolId, requiredItemId) {
    return axios
        .put(
            `/protocols/${protocolId}/requiredItems/${requiredItemId}/addToSummary`
        )
        .then(res => res.data);
}

export function removeRequiredItemFromSummaryApi(protocolId, requiredItemId) {
    return axios
        .put(
            `/protocols/${protocolId}/requiredItems/${requiredItemId}/removeFromSummary`
        )
        .then(res => res.data);
}

export function updateRequiredItemSummaryApi(protocolId, requiredItem) {
    return axios
        .put(
            `/protocols/${protocolId}/requiredItemsSummary/${requiredItem.id}`,
            requiredItem
        )
        .then(res => res.data);
}

export function postRequiredItemSummaryApi(
    protocolId,
    regulationScope,
    requiredItem
) {
    let url = `/protocols/${protocolId}/regulationScopes/${regulationScope}/requiredItemsSummary`;

    return axios.post(url, requiredItem).then(res => res.data);
}

export function deleteRequiredItemSummaryApi(protocolId, requiredItemId) {
    return axios
        .delete(
            `/protocols/${protocolId}/requiredItemsSummary/${requiredItemId}`
        )
        .then(res => res.data);
}
