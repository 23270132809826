import Vue from 'vue';
import Router from 'vue-router';

const Home = () => import('@/views/Home.vue');
const Login = () => import('@/views/authentication/Login.vue');
const Users = () => import('@/views/admin/Users.vue');
const Companies = () => import('@/views/admin/Companies.vue');
const SetPassword = () => import('@/views/authentication/SetPassword');
const Products = () => import('@/views/admin/Products');
const HSCodeProducts = () => import('@/views/admin/HSCodeProducts');
const Countries = () => import('@/views/admin/Countries');
const Languages = () => import('@/views/admin/Languages');
const Protocols = () => import('@/views/backoffice/Protocols');
const Substances = () => import('@/views/admin/Substances');
const Texts = () => import('@/views/backoffice/Texts');
const Components = () => import('@/views/admin/Components');
const ProtocolView = () => import('@/views/client/ProtocolView.vue');
const Requirements = () => import('@/views/backoffice/Requirements');
const Adeo = () => import('@/views/admin/Adeo');
const ProtocolRequests = () =>
    import('@/views/backoffice/adeo/ProtocolRequests');
const Dashboard = () => import('@/views/backoffice/Dashboard');
const ClientDashboard = () => import('@/views/client/DashboardView.vue');

const ClientProducts = () => import('@/views/client/ProductsView.vue');
const ProductSummaryView = () =>
    import('@/views/client/ProductSummaryView.vue');
const ConformityMarkings = () =>
    import('@/views/backoffice/ConformityMarkings');

import store from '@/store';

Vue.use(Router);

const router = new Router({
    linkActiveClass: 'active',
    mode: 'history',
    base: '',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/dashboard',
            name: 'ClientDashboard',
            component: ClientDashboard,
        },
        {
            path: '/products',
            name: 'ClientProducts',
            component: ClientProducts,
        },
        {
            path: '/product/:productId/:companyId?',
            name: 'ProductSummary',
            component: ProductSummaryView,
        },
        {
            path: '/protocol/:protocolId',
            name: 'Protocol',
            component: ProtocolView,
            props: true,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/setPassword/:userId/:token',
            name: 'SetPassword',
            component: SetPassword,
            props: true,
        },
        {
            path: '/admin',
            name: 'Admin',
        },
        {
            path: '/admin/users',
            name: 'Users',
            component: Users,
        },
        {
            path: '/admin/companies',
            name: 'Companies',
            component: Companies,
        },
        {
            path: '/admin/countries',
            name: 'Countries',
            component: Countries,
        },
        {
            path: '/admin/languages',
            name: 'Languages',
            component: Languages,
        },
        {
            path: '/admin/substances',
            name: 'Substances',
            component: Substances,
        },
        {
            path: '/admin/components/:categoryId?',
            name: 'Components',
            component: Components,
        },
        {
            path: '/admin/products/:companyId?',
            name: 'Products',
            component: Products,
        },
        {
            path: '/admin/hscodeproducts',
            name: 'HSCodeProducts',
            component: HSCodeProducts,
        },
        {
            path: '/admin/adeo',
            name: 'Adeo',
            component: Adeo,
        },
        {
            path: '/backoffice/texts/:countryId?',
            name: 'Texts',
            component: Texts,
        },
        {
            path: '/backoffice/protocols/:companyId?/:productId?/:countryId?',
            name: 'Protocols',
            component: Protocols,
        },
        {
            path: '/backoffice/requirements/:textId?',
            name: 'Requirements',
            component: Requirements,
        },
        {
            path: '/backoffice/protocol-requests/:requestId?',
            name: 'ProtocolRequests',
            component: ProtocolRequests,
        },
        {
            path: '/backoffice/dashboard',
            name: 'Dashboard',
            component: Dashboard,
        },
        {
            path: '/backoffice/conformity-markings',
            name: 'ConformityMarkings',
            component: ConformityMarkings,
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    console.log('entering router.beforeEach...');
    const loggedIn = await store.dispatch('users/isLoggedIn');
    if (loggedIn) {
        console.log('user already logged in.');
        /*if (to.query.redirect) {
            console.log(`redirecting to requested path: ${to.query.redirect}`);
            let { redirect } = to.query;
            const indexOfameli = redirect.indexOf('/portail/');
            if (indexOfameli >= 0) {
                const toStrip = indexOfameli + '/portail'.length;
                console.log(`stripping ${redirect.substring(0, toStrip + 1)} from url`);
                redirect = redirect.substring(toStrip + 1);
            }
            next(redirect);
            return;
        }*/
        if (to.name == 'Admin') {
            next({ name: 'AdminUsers' });
        }
        next();
        return;
    }
    if (to.path === '/logout') {
        console.log('path is logout.');
        next();
        return;
    }
    if (to.path === '/login') {
        console.log('path is login.');
        next();
        return;
    }
    if (to.name === 'SetPassword') {
        console.log('path is setPassword.');
        next();
        return;
    }
    console.log('user was not logged in. Redirecting to /login.');
    await store.dispatch('clearState');
    next({ name: 'Login' });
});

export default router;
