import axios from 'axios';
import store from '@/store';

const instance = axios.create({
    baseURL: `/api`,
    headers: {
        'Content-type': 'application/json',
    },
});

// Hook exécuté à chaque réponse reçue par axios
instance.interceptors.response.use(response => response, handleError);

function setupHeaders(headers) {
    if (headers) {
        instance.defaults.headers.common['Authorization'] = headers.token;
        instance.defaults.headers.common['X-PATool-Username'] =
            headers.username;
        instance.defaults.headers.common['X-PATool-Token-Expires'] =
            headers.expirationDate;
    }
}

function clearAuthorization() {
    instance.defaults.headers.common['Authorization'] = '';
}

/**
 * Gestion des erreurs axios
 * Stratégie :
 * 1/ on vérifie si on reçoit une réponse
 * 2/ si on peut on affiche un message clair en fonction du status de l'erreur (message)
 * 3/ on affiche un complément de message plus technique (messageComplementaire) :
 * libellé générique de l'erreur (error.response.statusText) puis on affiche ce que l'on connait en priorisant le message le plus précis
 *    =>  error.response.data.message, error.response.data, error.message
 * @param error interceptée par axios voir <a href="https://github.com/axios/axios#handling-errors">
 */
function handleError(error) {
    let title; // message que l'on souhaite afficher à l'utilisateur sur des erreurs classiques
    let message = ''; // message technique en complément pour aider la DSI à investiguer un problème

    if (error.response) {
        // La requête a été adressée et le serveur a répondu avec un code de status
        title = getErrorMessageFromStatus(error.response.status);
        if (error.response.data) {
            if (error.response.data.messages) {
                message += `${error.response.data.messages}`;
            } else if (error.response.data.message) {
                message += `${error.response.statusText} — ${error.response.data.message}`;
            } else {
                message += `${error.response.statusText} — Error (status ${error.response.status}) => ${error.response.data}`;
            }
        } else {
            message += `${error.response.statusText} — ${error.name} (status ${error.response.status}) => ${error.message}`;
        }
    } else if (error.request) {
        // Une requête a été faite mais pas de réponse reçue
        title = 'Server not responding';
        message += error.request;
    } else {
        // Autre erreur
        title = `Unknown error`;
        message += `Error ${error.name} => ${error.message}`;
    }

    // Messages à afficher par le composant UserMessages
    store.commit('userMessages/setAlert', {
        title,
        message,
        type: 'error',
    });
    return Promise.reject(error);
}

/**
 * Définition des messages non techniques pour les utilisateurs selon de status de l'erreur reçue
 * @param status de l'erreur
 * @returns {string} message à afficher en premier à l'utilisateur
 */
function getErrorMessageFromStatus(status) {
    let message = '';
    switch (status) {
        case 400:
            message += `Error`;
            break;
        case 401:
            message += `You are not authenticated or the user name and password are not valid - Please sign in again`;
            store.dispatch('users/logout', 'login');
            break;
        case 403:
            message += `Access denied`;
            store.dispatch('users/logout', 'login');
            break;
        case 404:
            message += `The resource you are asking for doesn't exist`;
            break;
        case 415:
            message += `The data you are attempting to save are not accepted by the server`;
            break;
        case 418:
            message += `I'm a teapot`;
            break;
        default:
            if (status >= 500) {
                message += `Internal server error`;
            } else {
                message += `Unknown error`;
            }
    }
    return message;
}

export default instance;
export { setupHeaders, clearAuthorization };
