import axios from './axiosInstance';
import { addUrlParameter } from '@/utils';

export function getProtocolByIdApi(id) {
    return axios.get(`/protocols/${id}`).then(res => res.data);
}
export function getProtocolByCompanyCountryAndProductApi(
    companyId,
    countryId,
    productId
) {
    return axios
        .get(
            `/companies/${companyId}/countries/${countryId}/products/${productId}/protocol`
        )
        .then(res => res.data);
}

export function getProtocolByComponentAndCountryApi(componentId, countryId) {
    return axios
        .get(`/components/${componentId}/countries/${countryId}/protocol`)
        .then(res => res.data);
}

export function postProtocolApi(companyId, countryId, productId) {
    return axios
        .post(
            `/companies/${companyId}/countries/${countryId}/products/${productId}/protocol`
        )
        .then(res => res.data);
}

export function copyProtocolApi(companyId, countryId, productId, protocolId) {
    return axios
        .post(
            `/companies/${companyId}/countries/${countryId}/products/${productId}/copy?protocolId=${protocolId}`
        )
        .then(res => res.data);
}

export function inheritProtocolApi(
    companyId,
    countryId,
    productId,
    protocolId
) {
    return axios
        .post(
            `/companies/${companyId}/countries/${countryId}/products/${productId}/inherit?protocolId=${protocolId}`
        )
        .then(res => res.data);
}

export function deleteProtocolApi(id) {
    return axios.delete(`/protocols/${id}`).then(res => res.data);
}

export function getConformityAssessmentTypesApi() {
    return axios.get('/conformityAssessmentTypes').then(res => res.data);
}

export function updateProtocolApi(protocol) {
    return axios
        .put(`/protocols/${protocol.id}`, protocol)
        .then(res => res.data);
}

export function getByCompanyAndSearchApi(
    companyId,
    search,
    page,
    itemsPerPage,
    done,
    outOfSync,
    countryIds,
    productIds
) {
    let url = `/companies/${companyId}/protocols`;
    if (search) {
        url += `?search=${search}`;
    }
    if (page >= 0) {
        url = addUrlParameter(url, 'page', page);
    }
    if (itemsPerPage) {
        url = addUrlParameter(url, 'itemsPerPage', itemsPerPage);
    }
    if (done) {
        url = addUrlParameter(url, 'done', done);
    }
    if (outOfSync) {
        url = addUrlParameter(url, 'outOfSync', outOfSync);
    }
    if (countryIds) {
        url = addUrlParameter(url, 'countryIds', countryIds);
    }
    if (productIds) {
        url = addUrlParameter(url, 'productIds', productIds);
    }
    return axios.get(url).then(res => res.data);
}

export function getProtocolTestTypesApi(ids) {
    return axios.post('/protocols/testTypes', ids).then(res => res.data);
}

export function getProtocolRiskLevelsApi() {
    return axios.get('/protocols/riskLevels').then(res => res.data);
}

export function getProtocolConformityMarkingsApi(ids) {
    return axios
        .post(`/protocols/conformityMarkings`, ids)
        .then(res => res.data);
}

export function createProtocolsFromRequestApi(requestId) {
    return axios
        .post(`/protocols/protocolRequest/${requestId}`)
        .then(res => res.data);
}

export function addTextToProtocolApi(protocolId, textId) {
    return axios
        .post(`/protocols/${protocolId}/texts/${textId}`)
        .then(res => res.data);
}

export function deleteTextFromProtocolApi(protocolId, textId) {
    return axios
        .delete(`/protocols/${protocolId}/texts/${textId}`)
        .then(res => res.data);
}

export function getProtocolTextsApi(protocolsId) {
    return axios.get(`/protocols/${protocolsId}/texts`).then(res => res.data);
}

export function getParentProtocolsApi(protocolId) {
    return axios.get(`/protocols/${protocolId}/parents`).then(res => res.data);
}

export async function exportProtocolApi(protocol) {
    const response = await axios({
        url: `/protocols/${protocol.id}/export`, //your url
        method: 'GET',
        responseType: 'blob', // important
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        `${protocol.product.name}_${protocol.product.reference}_${protocol.country.code}.xlsx`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export async function exportTestListApi(protocol, items) {
    const response = await axios({
        url: `/protocols/${protocol.id}/exportTestList`, //your url
        method: 'POST',
        responseType: 'blob', // important
        data: items,
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        `${protocol.product.name}_${protocol.product.reference}_${protocol.country.code}_tests.xlsx`
    ); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export async function getProtocolSummaryApi(protocolId) {
    return axios.get(`/protocols/${protocolId}/summary`).then(res => res.data);
}
