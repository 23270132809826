import axios from './axiosInstance';

export function getTestsByComponentIdAndCountryIdApi(countryId, componentId) {
    return axios
        .get(`/countries/${countryId}/components/${componentId}/tests`)
        .then(res => res.data);
}

export function getTestTypesApi() {
    return axios.get('/tests/types').then(res => res.data);
}
export function postTestApi(componentId, countryId, test) {
    return axios
        .post(`/countries/${countryId}/components/${componentId}/tests`, test)
        .then(res => res.data);
}
export function updateTestApi(test) {
    return axios.put(`/tests/${test.id}`, test).then(res => res.data);
}
export function deleteTestApi(id) {
    return axios.delete(`/tests/${id}`).then(res => res.data);
}
export function getTestsByRequiredItemIdApi(id) {
    return axios.get(`/requiredItems/${id}/tests`).then(res => res.data);
}
export function postTestByRequiredItemIdApi(id, test) {
    return axios.post(`/requiredItems/${id}/tests`, test).then(res => res.data);
}
export function getTestsByProtocolIdApi(id) {
    return axios.get(`/protocols/${id}/tests`).then(res => res.data);
}
export function getTestTypesByCompanyIdApi(id) {
    return axios.get(`/companies/${id}/testTypes`).then(res => res.data);
}
