import {
    getAllHSCodeProductsApi,
    postHSCodeProductsApi,
    deleteAllHSCodesProductsApi,
    deleteHSCodeProductApi,
    updateHSCodeProductApi,
} from '@/api/hsCodeProducts';

export const actions = {
    getAllHSCodeProducts() {
        return getAllHSCodeProductsApi();
    },
    postHSCodeProducts(context, products) {
        return postHSCodeProductsApi(products);
    },
    updateHSCodeProduct(context, product) {
        return updateHSCodeProductApi(product);
    },
    deleteHSCodeProduct(context, id) {
        return deleteHSCodeProductApi(id);
    },
    deleteAllHSCodesProducts() {
        return deleteAllHSCodesProductsApi();
    },
};

export const namespaced = true;
