import {
    getRequiredItemsByProtocolAndRegulationScopeApi,
    postRequiredItemApi,
    updateRequiredItemApi,
    deleteRequiredItemApi,
    copyRequiredItemApi,
    getRequiredItemsByTextIdApi,
    getDocumentsByProtocolIdApi,
    getRequiredItemsWithSymbolByProtocolApi,
    countRequiredItemsByProtocolApi,
    updateRequiredItemsApi,
    getProtocolRequiredItemsByTextAndRegulationScopeApi,
    addRequiredItemsToProtocolApi,
    copyAllRequiredItemsApi,
    deleteAllRequiredItemsApi,
    getRequiredItemsByTextAndRegulationScopeApi,
    getBySubstanceAndSearchApi,
    getByComponentAndSearchApi,
    getSkinContactTypesApi,
    excelExportByComponentAndSearchApi,
    excelExportBySubstanceAndSearchApi,
    getRequiredItemsByProtocolAndTestTypeApi,
    getAgeGradesApi,
    addRequiredItemToSummaryApi,
    removeRequiredItemFromSummaryApi,
    updateRequiredItemSummaryApi,
    postRequiredItemSummaryApi,
    deleteRequiredItemSummaryApi,
} from '@/api/requiredItems';

function initialState() {
    return {
        regulationScopes: [],
        requiredItemHeaders: [
            {
                value: 'position',
                text: '#',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: false,
            },
            {
                value: 'summary',
                text: 'Summary',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: false,
            },
            {
                value: 'adeoId',
                text: 'adeoId',
                scopes: ['Labelling', 'Packaging'],
                sortable: false,
                groupable: false,
                clientView: false,
            },
            {
                value: 'textCountries',
                text: 'Country',
                scopes: ['Substances and components'],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'requirementTypes',
                text: 'Type',
                scopes: ['Labelling', 'Packaging', 'Market product compliance'],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'productClassification',
                text: 'Product classification',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'itemVerification',
                text: 'Item verification',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'standards',
                text: 'Standards/regulations',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'standardMethodCitation',
                text: 'Standard method/citation/rule',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'substanceCategory',
                text: 'Substance category',
                scopes: ['Test view'],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'substance',
                text: 'Substance',
                scopes: [
                    'Packaging',
                    'Market product compliance',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'requirements',
                text: 'Legal requirements',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'labRequirements',
                text: 'Lab requirements',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'component',
                text: 'Component',
                scopes: [
                    'Packaging',
                    'Market product compliance',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'penalty',
                text: 'Penalty',
                scopes: ['Commercial', 'Declaration of conformity'],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'document',
                text: 'Document required',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'documentValidity',
                text: 'Document validity',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'documentReviewValidation',
                text: 'Doc. review validation',
                scopes: ['Documentation'],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'responsibility',
                text: 'Responsible party',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'conformityMarking',
                text: 'Symbol',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'ageGrade',
                text: 'Age grade',
                scopes: [
                    'Labelling',
                    'Market product compliance',
                    'Commercial',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'skinContactType',
                text: 'Skin contact',
                scopes: [
                    'Market product compliance',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'sampleSize',
                text: 'Sample size',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: false,
            },
            {
                value: 'mandatoryType',
                text: 'Mandatory',

                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                    'Substances and components',
                    'Test view',
                ],
                sortable: false,
                groupable: false,
                clientView: true,
            },
            {
                value: 'action',
                text: 'Actions',
                scopes: [
                    'Labelling',
                    'Packaging',
                    'Market product compliance',
                    'Documentation',
                    'Commercial',
                    'Declaration of conformity',
                ],
                sortable: false,
                groupable: false,
                clientView: false,
            },
        ],
        skinContactTypes: [],
        ageGrades: [],
    };
}
export const state = initialState();

export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setSkinContactTypes(state, types) {
        state.skinContactTypes = types;
    },
    setAgeGrades(state, ages) {
        state.ageGrades = ages;
    },
};

export const getters = {
    getHeadersByRegulationScope: state => (regulationScope, clientViewOnly) => {
        if (regulationScope) {
            let res = state.requiredItemHeaders.filter(h =>
                h.scopes.includes(regulationScope)
            );
            if (clientViewOnly) {
                res = res.filter(h => h.clientView);
            }
            return res;
        } else {
            return state.requiredItemHeaders;
        }
    },
};

export const actions = {
    getRequiredItemsByProtocolAndRegulationScope(
        context,
        { protocolId, regulationScope }
    ) {
        return getRequiredItemsByProtocolAndRegulationScopeApi(
            protocolId,
            regulationScope
        );
    },
    getDeclarationOfConformityRequiredItemsByProtocol(context, protocolId) {
        return getRequiredItemsByProtocolAndRegulationScopeApi(
            protocolId,
            'Declaration of conformity'
        );
    },
    postRequiredItem(context, { regulationScope, textId, requiredItem }) {
        return postRequiredItemApi(regulationScope, textId, requiredItem);
    },
    updateRequiredItem(context, requiredItem) {
        return updateRequiredItemApi(requiredItem);
    },
    deleteRequiredItem(context, { requiredItemId, protocolId }) {
        return deleteRequiredItemApi(requiredItemId, protocolId);
    },
    copyRequiredItem(context, requireItemId) {
        return copyRequiredItemApi(requireItemId);
    },
    getRequiredItemsByTextId(context, textId) {
        return getRequiredItemsByTextIdApi(textId);
    },
    getDocumentsByProtocolId(context, protocolId) {
        return getDocumentsByProtocolIdApi(protocolId);
    },
    getRequiredItemsWithSymbolByProtocol(context, protocolId) {
        return getRequiredItemsWithSymbolByProtocolApi(protocolId);
    },
    countRequiredItemsByProtocol(context, protocolId) {
        return countRequiredItemsByProtocolApi(protocolId);
    },
    updateRequiredItems(context, items) {
        return updateRequiredItemsApi(items);
    },
    getProtocolRequiredItemsByTextAndRegulationScope(
        context,
        { protocolId, textId, regulationScope }
    ) {
        return getProtocolRequiredItemsByTextAndRegulationScopeApi(
            protocolId,
            textId,
            regulationScope
        );
    },
    addRequiredItemsToProtocol(
        context,
        { protocolId, textId, requiredItemIds, regulationScope }
    ) {
        return addRequiredItemsToProtocolApi(
            protocolId,
            textId,
            requiredItemIds,
            regulationScope
        );
    },
    copyAllRequiredItems(
        context,
        { sourceId, regulationScope, destinationId }
    ) {
        return copyAllRequiredItemsApi(
            sourceId,
            regulationScope,
            destinationId
        );
    },
    deleteAllRequiredItems(context, { textId, regulationScope }) {
        return deleteAllRequiredItemsApi(textId, regulationScope);
    },
    getRequiredItemsByTextAndRegulationScope(
        context,
        { textId, regulationScope, protocolId }
    ) {
        return getRequiredItemsByTextAndRegulationScopeApi(
            textId,
            regulationScope,
            protocolId
        );
    },
    getBySubstanceAndSearch(
        context,
        {
            search,
            page,
            itemsPerPage,
            countryIds,
            componentIds,
            substanceIds,
            ageGrades,
            productIds,
            prioritarySubstances,
            itemVerification,
        }
    ) {
        return getBySubstanceAndSearchApi(
            search,
            page,
            itemsPerPage,
            countryIds,
            componentIds,
            substanceIds,
            ageGrades,
            productIds,
            prioritarySubstances,
            itemVerification
        );
    },
    getByComponentAndSearch(
        context,
        {
            search,
            page,
            itemsPerPage,
            countryIds,
            componentIds,
            substanceIds,
            ageGrades,
            productIds,
            prioritarySubstances,
            itemVerification,
        }
    ) {
        return getByComponentAndSearchApi(
            search,
            page,
            itemsPerPage,
            countryIds,
            componentIds,
            substanceIds,
            ageGrades,
            productIds,
            prioritarySubstances,
            itemVerification
        );
    },
    excelExportBySubstanceAndSearch(
        context,
        {
            search,
            countryIds,
            substanceIds,
            ageGrades,
            productIds,
            itemVerification,
        }
    ) {
        return excelExportBySubstanceAndSearchApi(
            search,
            countryIds,
            substanceIds,
            ageGrades,
            productIds,
            itemVerification
        );
    },
    excelExportByComponentAndSearch(
        context,
        {
            search,
            countryIds,
            componentIds,
            ageGrades,
            productIds,
            itemVerification,
        }
    ) {
        return excelExportByComponentAndSearchApi(
            search,
            countryIds,
            componentIds,
            ageGrades,
            productIds,
            itemVerification
        );
    },
    async fetchSkinContactTypes({ state, commit }) {
        if (state.skinContactTypes.length) {
            return state.skinContactTypes;
        }
        const res = await getSkinContactTypesApi();
        commit('setSkinContactTypes', res);
    },
    getRequiredItemsByProtocolAndTestType(context, { protocolId, testType }) {
        return getRequiredItemsByProtocolAndTestTypeApi(protocolId, testType);
    },
    async fetchAgeGrades({ state, commit }) {
        if (state.ageGrades.length) {
            return state.ageGrades;
        }
        const res = await getAgeGradesApi();
        commit('setAgeGrades', res);
    },
    async addRequiredItemToSummary(context, { protocolId, requiredItemId }) {
        return addRequiredItemToSummaryApi(protocolId, requiredItemId);
    },
    async removeRequiredItemFromSummary(
        context,
        { protocolId, requiredItemId }
    ) {
        return removeRequiredItemFromSummaryApi(protocolId, requiredItemId);
    },
    updateRequiredItemSummary(context, { protocolId, requiredItem }) {
        return updateRequiredItemSummaryApi(protocolId, requiredItem);
    },
    postRequiredItemSummary(
        context,
        { protocolId, regulationScope, requiredItem }
    ) {
        return postRequiredItemSummaryApi(
            protocolId,
            regulationScope,
            requiredItem
        );
    },
    deleteRequiredItemSummary(context, { protocolId, requiredItemId }) {
        return deleteRequiredItemSummaryApi(protocolId, requiredItemId);
    },
};

export const namespaced = true;
