function shorten(str, length) {
    return str
        ? str.length > length
            ? `${str.slice(0, length).trim()}...`
            : str
        : '';
}
function getTestTypeIcon(type) {
    switch (type) {
        case 'Chemical':
            return 'fa-flask';
        case 'Electrical safety':
            return 'fa-bolt';
        case 'Wireless':
            return 'fa-wifi';
        case 'Electromagnetic compatibility':
            return 'fa-magnet';
        case 'Flammability':
            return 'fa-fire';
        case 'Performance (physical and mechanical)':
            return 'fa-cogs';
        case 'Quality and workmanship':
            return 'fa-hammer';
        case 'Radioactivity':
            return 'fa-atom';
        case 'Toxicology':
            return 'fa-bacteria';
        case 'Hygiene and microbiology':
            return 'fa-pump-medical';
        case 'Ecodesign':
            return 'fa-recycle';
        case 'Documentation':
            return 'fa-book';
        case 'Energy efficiency':
            return 'fa-charging-station';
        case 'Labelling on product':
        case 'Labelling on packaging':
        case 'Labelling on packing':
            return 'fa-tag';
        case 'Packaging':
            return 'fa-box';
        case 'Safety':
            return 'fa-life-ring';
        case 'Environment':
            return 'fa-tree';
        case 'Human exposure':
            return 'fa-user';
        case 'Other':
            return 'fa-question';
        case 'Web':
            return 'fa-globe';
        default:
            return 'other';
    }
}

function groupBy(items, ...keys) {
    return items.reduce((result, item) => {
        let key = keys.reduce((r, k) => {
            if (r === '') return item[k];
            else return `${r}-${item[k]}`;
        }, '');
        if (key.id) {
            key = key.id;
        }
        return {
            ...result,
            [key]: [...(result[key] || []), item],
        };
    }, {});
}

function equalsIgnoreOrder(a, b) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;
        if (aCount !== bCount) return false;
    }
    return true;
}

function addUrlParameter(url, name, value) {
    if (url.indexOf('?') !== -1) {
        url += '&';
    } else {
        url += '?';
    }
    url += `${name}=${value}`;
    return url;
}

function getComponentLabel(component) {
    return `${component.name}${component.type ? ' > ' + component.type : ''}`;
}

export {
    shorten,
    getTestTypeIcon,
    groupBy,
    equalsIgnoreOrder,
    addUrlParameter,
    getComponentLabel,
};
