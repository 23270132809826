function initialState() {
    return {
        searchResultRoute: null,
    };
}

export const state = initialState();

export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setSearchResultRoute(state, value) {
        state.searchResultRoute = value;
    },
};
export const namespaced = true;
