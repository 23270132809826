import axios from './axiosInstance';

export function getAllCompaniesApi(type) {
    if (type) {
        return axios.get(`/companies?type=${type}`).then(res => res.data);
    } else {
        return axios.get('/companies').then(res => res.data);
    }
}
export function getClientsApi(id) {
    return axios.get(`/companies/${id}/clients`).then(res => res.data);
}
export function postCompanyApi(company) {
    return axios.post('/companies', company).then(res => res.data);
}
export function deleteCompanyApi(id) {
    return axios.delete(`/companies/${id}`).then(res => res.data);
}
export function updateCompanyApi(company) {
    return axios.put(`/companies/${company.id}`, company).then(res => res.data);
}
export function getTypesApi() {
    return axios.get('/companies/types').then(res => res.data);
}
