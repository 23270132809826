import {
    getAllCountriesApi,
    getAllRegionsApi,
    deleteCountryApi,
    postCountryApi,
    updateCountryApi,
} from '@/api/countries';

function initialState() {
    return {
        countries: [],
        regions: [],
        selectedCountry: null,
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    setRegions(state, regions) {
        state.regions = regions;
    },
    setSelectedCountry(state, country) {
        state.selectedCountry = country;
    },
    postCountry(state, country) {
        const existingCountry = state.countries.find(c => c.id === country.id);
        if (!existingCountry) {
            state.countries.push(country);
        }
    },
    updateCountry(state, country) {
        const index = state.countries.findIndex(c => c.id === country.id);
        if (index !== -1) {
            state.countries.splice(index, 1, country);
        }
    },
    deleteCountry(state, id) {
        const index = state.countries.findIndex(c => c.id === id);
        if (index !== -1) {
            state.countries.splice(index, 1);
        }
    },
};

export const actions = {
    async fetchCountries({ commit, state }) {
        if (state.countries.length) {
            return state.countries;
        }
        const countries = await getAllCountriesApi();
        commit('setCountries', countries);
    },
    async fetchRegions({ commit, state }) {
        if (state.regions.length) {
            return state.regions;
        }
        const regions = await getAllRegionsApi();
        commit('setRegions', regions);
    },
    async postCountry({ commit }, country) {
        const newCountry = await postCountryApi(country);
        commit('postCountry', newCountry);
    },
    async updateCountry({ commit }, country) {
        const updatedCountry = await updateCountryApi(country);
        commit('updateCountry', updatedCountry);
    },
    async deleteCountry({ commit }, id) {
        await deleteCountryApi(id);
        commit('deleteCountry', id);
    },
};

export const namespaced = true;
