function initialState() {
    return {
        alert: undefined,
    };
}
export const state = initialState();
export const mutations = {
    setAlert(state, alert) {
        state.alert = alert;
    },
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
};

export const getters = {
    alert(state) {
        return state.alert;
    },
};

export const namespaced = true;
