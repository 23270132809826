import axios from './axiosInstance';

export function getAllCountriesApi() {
    return axios.get('/countries').then(res => res.data);
}
export function getAllRegionsApi() {
    return axios.get('/countries?regions=true').then(res => res.data);
}
export function postCountryApi(country) {
    return axios.post('/countries', country).then(res => res.data);
}
export function deleteCountryApi(id) {
    return axios.delete(`/countries/${id}`).then(res => res.data);
}
export function updateCountryApi(country) {
    return axios.put(`/countries/${country.id}`, country).then(res => res.data);
}
