<template>
    <v-app>
        <user-messages></user-messages>
        <navigation title="C-Watch" />
        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import Navigation from '@/components/misc/Navigation';
import UserMessages from '@/components/misc/UserMessages';

export default {
    name: 'App',
    components: { UserMessages, Navigation },
    data() {
        return {
            //
        };
    },
};
</script>

<style></style>
