function initialState() {
    return {
        navigation: [
            { name: 'Home', to: 'Home', icon: 'home', children: null },
            {
                name: 'Protocol dashboard',
                to: 'ClientDashboard',
                icon: 'fa-clipboard-list',
                children: null,
            },
            {
                name: 'Browse products',
                to: 'ClientProducts',
                icon: 'fa-box-open',
                children: null,
            },
            {
                name: 'Admin',
                to: null,
                icon: 'fa-user-shield',
                roles: ['admin', 'superadmin'],
                children: [
                    {
                        name: 'Companies',
                        to: 'Companies',
                        icon: 'fa-industry',
                    },
                    {
                        name: 'Users',
                        to: 'Users',
                        icon: 'fa-users',
                    },
                    {
                        name: 'Countries',
                        to: 'Countries',
                        icon: 'fa-globe-europe',
                    },
                    {
                        name: 'Languages',
                        to: 'Languages',
                        icon: 'fa-language',
                    },
                    {
                        name: 'Substances',
                        to: 'Substances',
                        icon: 'fa-flask',
                    },
                    {
                        name: 'Components',
                        to: 'Components',
                        icon: 'fa-cubes',
                    },
                    {
                        name: 'Products',
                        to: 'Products',
                        icon: 'fa-box-open',
                    },
                    {
                        name: 'HS Code Products',
                        to: 'HSCodeProducts',
                        icon: 'fa-barcode',
                    },
                    {
                        name: 'Adeo',
                        to: 'Adeo',
                    },
                ],
            },
            {
                name: 'Back Office',
                to: null,
                icon: 'fa-desktop',
                roles: ['admin', 'superadmin', 'backoffice'],
                children: [
                    {
                        name: 'Conformity Markings',
                        to: 'ConformityMarkings',
                        icon: 'fa-recycle',
                    },
                    {
                        name: 'Texts',
                        to: 'Texts',
                        icon: 'fa-scroll',
                    },
                    {
                        name: 'Protocols',
                        to: 'Protocols',
                        icon: 'fa-clipboard-list',
                    },
                    {
                        name: 'Requirements',
                        to: 'Requirements',
                        icon: 'fa-list-ul',
                    },
                    {
                        name: 'Protocol Requests',
                        to: 'ProtocolRequests',
                        icon: 'fa-inbox',
                    },
                    {
                        name: 'Dashboard',
                        to: 'Dashboard',
                        icon: 'fa-columns',
                    },
                ],
            },
        ],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
};
export const namespaced = true;
