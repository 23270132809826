import {
    getProtocolByIdApi,
    getProtocolByCompanyCountryAndProductApi,
    postProtocolApi,
    deleteProtocolApi,
    getConformityAssessmentTypesApi,
    updateProtocolApi,
    getByCompanyAndSearchApi,
    copyProtocolApi,
    inheritProtocolApi,
    getProtocolTestTypesApi,
    getProtocolConformityMarkingsApi,
    createProtocolsFromRequestApi,
    addTextToProtocolApi,
    deleteTextFromProtocolApi,
    getProtocolTextsApi,
    getParentProtocolsApi,
    getProtocolRiskLevelsApi,
    exportProtocolApi,
    exportTestListApi,
    getProtocolSummaryApi,
} from '@/api/protocols';

function initialState() {
    return {
        conformityAssessmentTypes: [],
        riskLevels: [],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setConformityAssessmentTypes(state, types) {
        state.conformityAssessmentTypes = types;
    },
    setRiskLevels(state, levels) {
        state.riskLevels = levels;
    },
};
export const actions = {
    getProtocolById(context, id) {
        return getProtocolByIdApi(id);
    },
    getProtocolByCompanyCountryAndProduct(
        context,
        { companyId, countryId, productId }
    ) {
        return getProtocolByCompanyCountryAndProductApi(
            companyId,
            countryId,
            productId
        );
    },
    postProtocol(context, { companyId, countryId, productId }) {
        return postProtocolApi(companyId, countryId, productId);
    },
    copyProtocol(context, { companyId, countryId, productId, protocolId }) {
        return copyProtocolApi(companyId, countryId, productId, protocolId);
    },
    inheritProtocol(context, { companyId, countryId, productId, protocolId }) {
        return inheritProtocolApi(companyId, countryId, productId, protocolId);
    },
    deleteProtocol(context, id) {
        return deleteProtocolApi(id);
    },
    async fetchConformityAssessmentTypes({ commit, state }) {
        if (state.conformityAssessmentTypes.length) {
            return state.conformityAssessmentTypes.length;
        }
        const types = await getConformityAssessmentTypesApi();
        commit('setConformityAssessmentTypes', types);
    },
    getByCompanyAndSearch(
        context,
        {
            companyId,
            search,
            page,
            itemsPerPage,
            done,
            outOfSync,
            countryIds,
            productIds,
        }
    ) {
        return getByCompanyAndSearchApi(
            companyId,
            search,
            page,
            itemsPerPage,
            done,
            outOfSync,
            countryIds,
            productIds
        );
    },
    updateProtocol(context, protocol) {
        return updateProtocolApi(protocol);
    },
    getProtocolTestTypes(context, ids) {
        return getProtocolTestTypesApi(ids);
    },
    getProtocolConformityMarkings(context, ids) {
        return getProtocolConformityMarkingsApi(ids);
    },
    createProtocolsFromRequest(context, requestId) {
        return createProtocolsFromRequestApi(requestId);
    },
    addTextToProtocol(context, { protocolId, textId }) {
        return addTextToProtocolApi(protocolId, textId);
    },
    deleteTextFromProtocol(context, { protocolId, textId }) {
        return deleteTextFromProtocolApi(protocolId, textId);
    },
    getProtocolTexts(context, protocolId) {
        return getProtocolTextsApi(protocolId);
    },
    getParentProtocols(context, protocolId) {
        return getParentProtocolsApi(protocolId);
    },
    async fetchProtocolRiskLevels({ commit }) {
        const levels = await getProtocolRiskLevelsApi();
        commit('setRiskLevels', levels);
    },
    exportProtocol(context, protocol) {
        return exportProtocolApi(protocol);
    },
    exportTestList(context, { protocol, items }) {
        return exportTestListApi(protocol, items);
    },
    getProtocolSummary(context, protocolId) {
        return getProtocolSummaryApi(protocolId);
    },
};
export const namespaced = true;
