import axios from './../axiosInstance';

export function synchronizeRegulationsApi() {
    return axios.get(`/regulations/synchronize`).then(res => res.data);
}

export function createOrUpdateRegulationApi(texts) {
    return axios.put('/regulations', texts);
}

export function getRegulationApi(id) {
    return axios.get(`/regulations/${id}`).then(res => res.data);
}
