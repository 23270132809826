<template>
    <v-alert
        width="350"
        v-if="alert"
        :value="alert !== null"
        dismissible
        :type="alert.type"
        @input="onInput"
        border="top"
        style="right: 20px"
        transition="fade-transition"
        @click="setAlert(null)"
    >
        <h3>{{ alert.title }}</h3>
        <span class="text-body-2">{{ alert.message }}</span>
    </v-alert>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'UserMessages',
    created() {
        setTimeout(() => this.setAlert(null), 3000);
    },
    computed: {
        ...mapGetters('userMessages', ['alert']),
    },
    methods: {
        ...mapMutations('userMessages', ['setAlert']),
        onInput(value) {
            if (!value) {
                this.setAlert(null);
            }
        },
    },
    watch: {
        alert(val) {
            if (val && val.type !== 'error') {
                setTimeout(() => this.setAlert(null), 3000);
            }
        },
    },
};
</script>

<style scoped>
.v-alert {
    position: fixed;
    z-index: 1000;
    right: 0px;
}
</style>
