import axios from '@/api/axiosInstance';

export function uploadConformityMarkingApi(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/files/conformityMarkings/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
}

export function getConformityMarkingsApi() {
    return axios.get(`/conformityMarkings`).then(res => res.data);
}

export function updateConformityMarkingApi(marking) {
    return axios
        .put(`/conformityMarkings/${marking.id}`, marking)
        .then(res => res.data);
}
export function addConformityMarkingApi() {
    return axios.post('/conformityMarkings').then(res => res.data);
}
