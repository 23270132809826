import axios from './../axiosInstance';

export function synchronizeQualityModelsApi() {
    return axios.get(`/qualityModels/synchronize`).then(res => res.data);
}

export function synchronizeProtocolApi(id) {
    return axios.post(`/protocols/${id}/synchronize`).then(res => res.data);
}

export function previewProtocolSynchronisationApi(id) {
    return axios
        .post(`/protocols/${id}/synchronize?preview=true`)
        .then(res => res.data);
}

export function getQMSRegulationNotInCWatchAPI(id) {
    return axios
        .get(`/protocols/${id}/regulationsNotInCWatch`)
        .then(res => res.data);
}
