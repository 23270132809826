import { getAllLanguagesApi } from '@/api/languages';
import {
    deleteLanguageApi,
    postLanguageApi,
    updateLanguageApi,
} from '@/api/languages';

function initialState() {
    return {
        languages: [],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setLanguages(state, languages) {
        state.languages = languages;
    },
    postLanguage(state, language) {
        const existingLanguage = state.languages.find(
            c => c.id === language.id
        );
        if (!existingLanguage) {
            state.languages.push(language);
        }
    },
    updateLanguage(state, language) {
        const index = state.languages.findIndex(c => c.id === language.id);
        if (index !== -1) {
            state.languages.splice(index, 1, language);
        }
    },
    deleteLanguage(state, id) {
        const index = state.languages.findIndex(c => c.id === id);
        if (index !== -1) {
            state.languages.splice(index, 1);
        }
    },
};

export const actions = {
    async fetchLanguages({ commit }) {
        const languages = await getAllLanguagesApi();
        commit('setLanguages', languages);
    },
    async postLanguage({ commit }, language) {
        const newLanguage = await postLanguageApi(language);
        commit('postLanguage', newLanguage);
    },
    async updateLanguage({ commit }, language) {
        const updatedLanguage = await updateLanguageApi(language);
        commit('updateLanguage', updatedLanguage);
    },
    async deleteLanguage({ commit }, id) {
        await deleteLanguageApi(id);
        commit('deleteLanguage', id);
    },
};

export const namespaced = true;
