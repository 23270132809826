import {
    getTestsByComponentIdAndCountryIdApi,
    getTestTypesApi,
    postTestApi,
    updateTestApi,
    deleteTestApi,
    getTestsByRequiredItemIdApi,
    postTestByRequiredItemIdApi,
    getTestsByProtocolIdApi,
    getTestTypesByCompanyIdApi,
} from '@/api/tests';

function initialState() {
    return {
        testTypes: [],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setTestTypes(state, types) {
        state.testTypes = types;
    },
};
export const actions = {
    getTestsByComponentIdAndCountryId(context, { countryId, componentId }) {
        return getTestsByComponentIdAndCountryIdApi(countryId, componentId);
    },
    async fetchTestTypes({ commit, state }) {
        if (state.testTypes.length) {
            return state.testTypes;
        }
        const types = await getTestTypesApi();
        commit('setTestTypes', types);
    },
    postTest(context, { componentId, countryId, test }) {
        return postTestApi(componentId, countryId, test);
    },
    updateTest(context, test) {
        return updateTestApi(test);
    },
    deleteTest(context, id) {
        return deleteTestApi(id);
    },
    getTestsByRequiredItemId(context, id) {
        return getTestsByRequiredItemIdApi(id);
    },
    postTestByRequiredItemId(context, { requiredItemId, test }) {
        return postTestByRequiredItemIdApi(requiredItemId, test);
    },
    getTestsByProtocolId(context, id) {
        return getTestsByProtocolIdApi(id);
    },
    getTestTypesByCompanyId(context, id) {
        return getTestTypesByCompanyIdApi(id);
    },
};
export const namespaced = true;
