import axios from '@/api/axiosInstance';

export function getDocumentTypesApi() {
    return axios.get('/documents/types').then(res => res.data);
}

export function uploadDocumentFileApi(file, onUploadProgress) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(`/files/documents/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
}
