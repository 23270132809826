import axios from './axiosInstance';

export function getUserByNameApi(name) {
    return axios.get(`/users/findByLogin/?login=${name}`).then(res => res.data);
}

export function getUserByIdAndTokenApi(id, token) {
    return axios
        .get(`/users/findById/?id=${id}&token=${token}`)
        .then(res => res.data);
}

export function getAllUsersApi() {
    return axios.get('/users').then(res => res.data);
}

export function postUserApi(user) {
    return axios.post('/users', user).then(res => res.data);
}

export function setPasswordApi(id, password) {
    return axios
        .put(`/users/${id}/setPassword?password=${password}`)
        .then(res => res.data);
}

export function deleteUserApi(id) {
    return axios.delete(`/users/${id}`).then(res => res.data);
}
export function updateUserApi(user) {
    return axios.put(`/users/${user.id}`, user).then(res => res.data);
}

export function getRolesApi() {
    return axios.get('/users/roles').then(res => res.data);
}

export function login(username, password) {
    return axios
        .post('/login', { username, password })
        .then(res => res.headers.authorization);
}
