import axios from './axiosInstance';

export function getProductsByCompanyIdApi(companyId, protocolOnly) {
    let url = '/products';
    if (companyId) {
        url += `?companyId=${companyId}`;
        if (protocolOnly) {
            url += `&protocolOnly=true`;
        }
    }

    return axios.get(url).then(res => res.data);
}
export function postProductsApi(companyId, products) {
    let url = '/products';
    if (companyId) {
        url += `?companyId=${companyId}`;
    }
    return axios.post(url, products).then(res => res.data);
}
export function deleteAllProductsApi(companyId) {
    let url = '/products';
    if (companyId) {
        url += `?companyId=${companyId}`;
    }
    return axios.delete(url).then(res => res.data);
}
export function deleteProductApi(id) {
    return axios.delete(`/products/${id}`).then(res => res.data);
}
export function updateProductApi(product) {
    return axios.put(`/products/${product.id}`, product).then(res => res.data);
}
export function getProductComponentsApi(productId) {
    return axios.get(`/products/${productId}/components`).then(res => res.data);
}

export function getProductApi(productId) {
    return axios.get(`/products/${productId}`).then(res => res.data);
}
