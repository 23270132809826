import {
    getProductCompliancesByProtocolIdApi,
    postProductCompliance,
    deleteProductComplianceApi,
    updateProductComplianceApi,
} from '@/api/productCompliances';

export const actions = {
    getProductCompliancesByProtocolId(context, protocolId) {
        return getProductCompliancesByProtocolIdApi(protocolId);
    },
    postProductCompliance(context, protocolId) {
        return postProductCompliance(protocolId);
    },
    deleteProductCompliance(context, id) {
        return deleteProductComplianceApi(id);
    },
    updateProductCompliance(context, compliance) {
        return updateProductComplianceApi(compliance);
    },
};
export const namespaced = true;
