import {
    getProductsByCompanyIdApi,
    postProductsApi,
    deleteProductApi,
    updateProductApi,
    deleteAllProductsApi,
    getProductComponentsApi,
    getProductApi,
} from '@/api/products';

function initialState() {
    return {
        selectedProduct: null,
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setSelectedProduct(state, product) {
        state.selectedProduct = product;
    },
};

export const actions = {
    getProductsByCompanyId(context, companyId) {
        return getProductsByCompanyIdApi(companyId);
    },
    getProtocolProductsByCompanyId(context, companyId) {
        return getProductsByCompanyIdApi(companyId, true);
    },
    postProducts(context, { companyId, products }) {
        return postProductsApi(companyId, products);
    },
    updateProduct(context, product) {
        return updateProductApi(product);
    },
    deleteProduct(context, id) {
        return deleteProductApi(id);
    },
    deleteAllProducts(context, companyId) {
        return deleteAllProductsApi(companyId);
    },
    getProductComponents(context, productId) {
        return getProductComponentsApi(productId);
    },
    getProduct(context, productId) {
        return getProductApi(productId);
    },
};

export const namespaced = true;
