import {
    getAllSubstancesApi,
    postSubstancesApi,
    deleteAllSubstancesApi,
    deleteSubstanceApi,
    updateSubstanceApi,
    getAllSubstanceCategoriesApi,
    getPrioritarySubstancesApi,
} from '@/api/substances';

function initialState() {
    return {
        substances: [],
        categories: [],
        prioritarySubstances: [],
    };
}
export const state = initialState();
export const mutations = {
    clearState(state) {
        const s = initialState();
        Object.keys(s).forEach(key => {
            state[key] = s[key];
        });
    },
    setSubstances(state, substances) {
        state.substances = substances;
    },
    setPrioritarySubstances(state, substances) {
        state.prioritarySubstances = substances;
    },
    setCategories(state, categories) {
        state.categories = categories;
    },
    postSubstances(state, substances) {
        state.substances.push(...substances);
    },
    updateSubstance(state, substance) {
        let index = state.substances.findIndex(p => p.id === substance.id);
        if (index !== -1) {
            state.substances.splice(index, 1, substance);
        }
    },
    deleteSubstance(state, id) {
        const index = state.substances.findIndex(p => p.id === id);
        if (index !== -1) {
            state.substances.splice(index, 1);
        }
    },
};

export const actions = {
    async fetchSubstanceCategories({ commit }) {
        const categories = await getAllSubstanceCategoriesApi();
        commit('setCategories', categories);
    },
    async fetchSubstances({ commit }, categoryId) {
        const substances = await getAllSubstancesApi(categoryId);
        commit('setSubstances', substances);
    },
    async postSubstances({ commit }, substances) {
        const newSubstances = await postSubstancesApi(substances);
        commit('postSubstances', newSubstances);
        return newSubstances;
    },
    async updateSubstance({ commit }, substance) {
        const updatedSubstance = await updateSubstanceApi(substance);
        commit('updateSubstance', updatedSubstance);
        return updatedSubstance;
    },
    async deleteSubstance({ commit }, id) {
        await deleteSubstanceApi(id);
        commit('deleteSubstance', id);
    },
    async deleteAllSubstances({ commit }) {
        await deleteAllSubstancesApi();
        commit('setSubstances', []);
    },
    async fetchPrioritarySubstances({ commit }, countryId) {
        const substances = await getPrioritarySubstancesApi(countryId);
        commit('setPrioritarySubstances', substances);
    },
};

export const namespaced = true;
