import axios from './../axiosInstance';

export function getAllProtocolRequestsApi(completed) {
    return axios
        .get(`/protocolRequests?completed=${completed}`)
        .then(res => res.data);
}

export function getRequestProtocolsApi(requestId) {
    return axios
        .get(`/protocolRequests/${requestId}/protocols`)
        .then(res => res.data);
}

export function deleteProtocolRequestApi(requestId) {
    return axios.delete(`/protocolRequests/${requestId}`).then(res => res.data);
}

export function completeProtocolRequestApi(requestId, answer) {
    return axios
        .put(`/protocolRequests/${requestId}?answer=${answer}`)
        .then(res => res.data);
}
